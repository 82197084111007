import { sensorsRegister } from '@/util/sensorsdata'

export default {
  data() {
    return {
      openLangImg: false,
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/images/header/en.png'),
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/images/header/fr.png'),
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/images/header/es.png'),
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/images/header/pt.png'),
        },
        {
          value: 'ko',
          label: '한국어',
          icon: require('@/assets/images/header/ko.png'),
        },
        {
          value: 'zh_CN',
          label: '中文',
          icon: require('@/assets/images/header/cn.png'),
        },
        {
          value: 'ja',
          label: '日本語',
          icon: require('@/assets/images/header/ja.png'),
        },
        {
          value: 'th',
          label: 'ภาษาไทย',
          icon: require('@/assets/images/header/th.png'),
        },
        {
          value: 'vi',
          label: 'Tiếng Việt',
          icon: require('@/assets/images/header/vi.png'),
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/images/header/de.png'),
        },
        {
          value: 'id',
          label: 'Indonesian',
          icon: require('@/assets/images/header/id.png'),
        },
        {
          value: 'ar',
          label: 'عربي',
          icon: require('@/assets/images/header/ar.png'),
        },
        // {
        //   value: 'it',
        //   label: 'Italian',
        //   icon: require('@/assets/images/header/it.png')
        // },
      ],
    }
  },
  watch: {
    language(lang) {
      this.lang = lang.value
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr'
    },
    lang: {
      immediate: true,
      handler(val) {
        // 如果找不到預設第一筆
        this.language = this.languages.find(f => f.value == val) || this.languages[0]
        this.$nextTick(function() {
          sensorsRegister({ current_lang: val });
          try {
            zE('messenger:set', 'locale', this.switchPUGLang(val))
          } catch (error) {
            console.log('zE messenger', error);
          }
        })
        this.setFontFamily(val)
      },
    },
  },
  computed: {
    lang: {
      get() {
        return this.$store.state.common.lang
      },
      set(value) {
        return this.$store.commit('common/setLang', value)
      },
    },
  },
  methods: {
    addLangStyle(bool) {
      this.openLangImg = bool
    },
    switchPUGLang(lang) {
      switch (lang) {
        case 'en_US':
          return 'en'
        case 'zh_CN':
          return 'zh-CN'
        case 'fr_FR':
          return 'fr'
        case 'ja':
          return 'ja'
        case 'ko':
          return 'ko'
        case 'vi':
          return 'vi'
        case 'th':
          return 'th'
        case 'es':
          return 'es'
        case 'pt':
          return 'pt'
        case 'de':
          return 'de'
        case 'id':
          return 'id'
        case 'ar':
          return 'ar'
        default:
          return 'en'
      }
    },
    setFontFamily(lang) {
      if (lang === 'vi') {
        document.body.style.setProperty('font-family', 'sans-serif', 'important')
      } else {
        document.body.style.setProperty('font-family', 'Avenir', 'important')
      }
    },
  },
  mounted() {
    const callback = (val, oldVal, uri) => {
      console.log('localStorage change', val)
      if (val != this.userName) location.reload()
    }

    this.$ls.on('setUserName', callback) //watch change foo key and triggered callback
  },
}
